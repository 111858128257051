import { addDays, addMonths, isBefore, startOfDay } from "date-fns";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

export function getFingerprint() {
  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
    // Return the promise chain
    return FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        localStorage.setItem("deviceId", result.visitorId);
        return result.visitorId; // Return new deviceId after setting it
      });
  }

  // Return deviceId directly if it already exists
  return deviceId;
}

export const calCulateOverallprice = (data: any) => {
  let price = 0;

  for (const item of data) {
    price = price + parseFloat(item?.pricing?.total) * parseInt(item?.quantity);
  }
  return price.toString();
};
export const calCulateOverallprice2 = (data: any) => {
  let price = 0;

  if (data) {
    for (const item of data) {
      price = price + parseFloat(item?.total) * parseInt(item?.travelersize);
    }
  }

  return price.toString();
};
export const calCulateOverallBasePrice = (data: any) => {
  let price = 0;

  if (data) {
    for (const item of data) {
      price = price + parseFloat(item?.base) * parseInt(item?.travelersize);
    }
  }

  return price.toString();
};
export const calCulateOverallFeesPrice = (data: any) => {
  let price = 0;

  if (data) {
    for (const item of data) {
      let fee = 0;
      for (const x of item.fees) {
        fee = fee + x.value;
      }
      price = price + fee * parseInt(item?.travelersize);
    }
  }

  return price.toString();
};

export const calpassengerCount = (data: any) => {
  let count = 0;

  if (data) {
    for (const item of data) {
      count = count + parseInt(item?.quantity);
    }
  }

  return count.toString();
};
export const generateMatrix = (airlines: any, stops: any) => {
  if (airlines && stops) {
    const allAirlines = Object.keys(airlines);
    const allStops = Object.keys(stops);

    const matrix: any = {};

    // Initialize the matrix
    allStops.forEach((stop) => {
      matrix[stop] = {};
      allAirlines.forEach((airline) => {
        matrix[stop][airline] = [];
      });
    });

    // Fill the matrix with data
    allStops.forEach((stopType: string) => {
      stops[stopType].forEach((stopId: string) => {
        allAirlines.forEach((airline: any) => {
          if (airlines[airline].includes(stopId)) {
            matrix[stopType][airline].push(stopId);
          }
        });
      });
    });

    return { allAirlines, allStops, matrix };
  } else {
    return { allAirlines: [], allStops: [], matrix: {} };
  }
};

export const sortAddons = (addOns: any) => {
  const groups: any = {};

  addOns?.forEach((addOn: any) => {
    if (!groups[addOn.type]) {
      groups[addOn.type] = [];
    }

    if (addOn.type === "Meal") {
      for (let i = 1; i <= 10; i++) {
        groups[addOn.type].push({
          ...addOn,
          description: `${addOn.description} (x${i}) `,
          quantity: i,
          price: addOn.price * i,
        });
      }
    } else if (addOn.type === "BAGGAGE") {
      groups[addOn.type].push({
        ...addOn,
        quantity: 1,
      });
    }
  });

  const groupedOptions = Object.keys(groups).map((type) => ({
    label: type,
    options: groups[type],
  }));

  return groupedOptions;
};

export function calculateTotalPrice(data: any) {
  let totalPrice = 0;

  for (const passengerType in data) {
    data[passengerType].forEach((addOn: any) => {
      totalPrice += addOn.price * (addOn.quantity || 1);
    });
  }

  return totalPrice;
}
export function extractFirstStrings(data: any) {
  let firstStrings = [];

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      let firstString = data[key][0];
      firstStrings.push(firstString);
    }
  }

  return firstStrings;
}

export function adjustDates(dateRange: any) {
  const { from, to } = dateRange;

  const currentDate = new Date();
  const fromDate = new Date(from.year, from.month - 1, from.day);

  // Check if the 'from' date is in the past
  if (isBefore(fromDate, currentDate)) {
    const adjustedFromDate = addMonths(currentDate, 1);

    // If 'to' date is not null, add one week interval from the new 'from' date
    if (to) {
      const adjustedToDate = addDays(adjustedFromDate, 7);
      return {
        from: {
          day: adjustedFromDate.getDate(),
          month: adjustedFromDate.getMonth() + 1,
          year: adjustedFromDate.getFullYear(),
        },
        to: {
          day: adjustedToDate.getDate(),
          month: adjustedToDate.getMonth() + 1,
          year: adjustedToDate.getFullYear(),
        },
      };
    } else {
      return {
        from: {
          day: adjustedFromDate.getDate(),
          month: adjustedFromDate.getMonth() + 1,
          year: adjustedFromDate.getFullYear(),
        },
        to: null,
      };
    }
  } else {
    // If 'from' date is not in the past, no adjustments needed
    return dateRange;
  }
}

export const standardizeDate = (date: string) => {
  if (!date) return null;
  // Convert to start of day in local timezone
  const standardDate = startOfDay(new Date(date));
  // Reset hours to avoid DST issues
  standardDate.setHours(12, 0, 0, 0);
  return standardDate;
};

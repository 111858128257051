import { CSSProperties, useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import RiseLoader from "react-spinners/RiseLoader";
import { errorMessage } from "../util/Helpers";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#000046",
};
export default function StripeForm({ action, isLoading, setIsLoading }: any) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");

  const [paymentIntent, setPaymentIntent] = useState<any>(null);
  console.log(message);
  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!paymentIntent) {
      return;
    }

    stripe
      .retrievePaymentIntent(paymentIntent.client_secret)
      .then(({ paymentIntent }: any) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            action();
            break;
          case "processing":
            setMessage("Your payment is processing.");
            errorMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            errorMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
  }, [stripe, paymentIntent]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (setIsLoading) {
      setIsLoading(true);
    }

    const intent: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "",
      },
      redirect: "if_required",
    });
    setPaymentIntent(intent.paymentIntent ? intent.paymentIntent : null);

    if (intent && intent.error) {
      errorMessage(intent.error.message);
    }
    if (setIsLoading) {
      setIsLoading(false);
    }
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <br />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        type="submit"
        className="bg-orange-500 disabled:bg-orange-200 w-full my-2 cursor-pointer font-semibold text-lg rounded-md h-12  text-white items-center justify-center"
      >
        {isLoading ? (
          <div className="w-full flex justify-center" key={0}>
            <RiseLoader
              color={"#ffffff"}
              loading={true}
              cssOverride={override}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div>{"Pay now"}</div>
        )}
      </button>
    </form>
  );
}

import { useState, Dispatch } from "react";
import OtpInput from "react18-input-otp";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions";
import { useModal } from "../../Sliders/Modal/ModalContext";
import VerifyCoutdown from "../../util/VerifyCoutdown";

const Second = ({ currPage, setCurrPage, modalId }: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const email = useSelector((state: any) => state.client.email);
  const loading = useSelector((state: any) => state.client.loading);
  const { hideModal } = useModal();

  const [otp, setOtp] = useState("");

  const handleChange = (otpValue: any) => {
    setOtp(otpValue);
  };
  return (
    <div>
      <div className="py-2">
        <div className="font-Inter font-normal text-xl mt-10 text-[#1c1a3a] text-center my-3">
          We have sent a confirmation code to
          <span className="text-xl font-bold inline-flex">{email}</span>
        </div>
        <div>
          <div className="mt-6 mb-1">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              isInputNum={true}
              // separator={<span>-</span>}
              inputStyle={{
                width: "100%",
                height: "60px",
                marginLeft: "5px",
                marginRight: "5px",
                color: "#1c1a3a",
                fontSize: "32px",
                fontWeight: 600,
                borderBottom: "1px solid #4A4A4A",
                background: "none",
              }}
            />
          </div>
          <div className="my-5">
            <button
              type="button"
              onClick={() => {
                console.log("sds");
                dispatch(
                  Actions.verifyClient(
                    { email, otp },
                    (data: any) => {
                      if (data.token) {
                        if (data.client.fullname && data.client.phone) {
                          hideModal(modalId);
                          setCurrPage(1);
                        } else {
                          setCurrPage(currPage + 1);
                        }
                      } else {
                        hideModal(modalId);
                      }
                    },
                    () => {}
                  )
                );
                // setIsOpen(!isOpen);
              }}
              className=" w-full bg-[#1c1a3a] justify-center text-base items-center gap-2 flex text-[#fff] font-bold px-4 py-3 rounded hover:bg-[#1c1a3a] focus:outline-none focus:bg-[#1c1a3a]"
            >
              {loading ? "Loading..." : "Sign In"}
            </button>
          </div>
          <div className="text-[#9A9A9A] flex items-center space-x-2 justify-center w-full text-xs text-center mt-8">
            <span>code expires in</span>
            <VerifyCoutdown
              action={() => {
                setCurrPage(1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Second;
